import React, { useState } from "react";
import { Scrollchor, easeOutQuad } from "react-scrollchor";

const Menu = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <div className="header-menu">
            <div className={menuOpen ? "menu-icon open" : "menu-icon"} onClick={() => setMenuOpen(!menuOpen)}>
                <span></span>
                <span></span>
                <span></span>
            </div>

            <nav className={menuOpen ? "menu open" : "menu"}>
                <ul className="flex flex-col md:flex-row gap-4 md:gap-3 xl:gap-4">
                    <li>
                        <Scrollchor to="">Start</Scrollchor>
                    </li>
                    <li>
                        <Scrollchor to="o-nas" animate={{ offset: -80, duration: 1000, easing: easeOutQuad }}>
                            O nas
                        </Scrollchor>
                    </li>
                    <li>
                        <Scrollchor to="partnerzy" animate={{ offset: -100, duration: 1000, easing: easeOutQuad }}>
                            Partnerzy
                        </Scrollchor>
                    </li>
                    <li>
                        <Scrollchor to="nasze-programy" animate={{ offset: -100, duration: 1000, easing: easeOutQuad }}>
                            Nasze programy
                        </Scrollchor>
                    </li>
                    <li>
                        <Scrollchor to="wesprzyj-nas" animate={{ offset: -100, duration: 1000, easing: easeOutQuad }}>
                            Wesprzyj nas
                        </Scrollchor>
                    </li>
                    <li>
                        <Scrollchor to="kontakt" animate={{ offset: -120, duration: 1000, easing: easeOutQuad }}>
                            Kontakt
                        </Scrollchor>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default Menu;
