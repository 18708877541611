import React from "react";
import Header from "./Header";
import Slider from "./Slider";
import Boxes from "./Boxes";
import AboutUs from "./AboutUs";
import TwoBox from "./TwoBox";
import Partners from "./Partners";
import Actions from "./Actions";
import Donation from "./Donation";
import BankAccount from "./BankAccount";
import Contact from "./Contact";
import Footer from "./Footer";

const Home = () => {
    return (
        <div className="">
            <Header />
            <Slider />
            <Boxes />
            <AboutUs />
            <TwoBox />
            <Partners />
            <Actions />
            <Donation />
            <BankAccount />
            <Contact />
            <Footer />
        </div>
    );
};

export default Home;
