import React from "react";
import { CurrencyEuroIcon, UserIcon } from "@heroicons/react/24/outline";
import { Scrollchor, easeOutQuad } from "react-scrollchor";

const Donation = () => {
    return (
        <section className="donation" id="wesprzyj-nas">
            <div className="container mx-auto pt-24 pb-8">
                <div className="flex flex-col items-center">
                    <h2>Wesprzyj nasze działania</h2>
                    <h4 className="mt-6 font-bold text-xl text-primary">Dlaczego warto nas wspierać?</h4>
                    <div className="line-sep line-center w-full"></div>
                </div>
            </div>
            <div className="container mx-auto">
                <div className="flex flex-col md:flex-row gap-6">
                    <div className="p-8 xl:p-12 md:w-2/6 flex flex-col items-start bg-white shadow-lg">
                        <span className="p-4 w-auto border border-text rounded-full text-primary mb-6">
                            <UserIcon className="w-6 h-6" />
                        </span>
                        <h4 className="font-body font-bold mb-4">Zostań sponsorem</h4>
                        <p>
                            Jako sponsor fundacji aktywnie wspierającej rozwój dzieci, możesz mieć bezpośredni wpływ na
                            kształtowanie przyszłości młodych jednostek. Twoje wsparcie finansowe umożliwi nam
                            kontynuowanie naszej misji, dostarczając niezbędne zasoby i programy, które pomogą dzieciom
                            osiągnąć pełen potencjał i stworzyć lepszą przyszłość.
                        </p>
                    </div>
                    <div className="p-8 xl:p-12 md:w-2/6 flex flex-col items-start bg-white shadow-lg">
                        <span className="p-4 w-auto border border-text rounded-full text-primary mb-6">
                            <UserIcon className="w-6 h-6" />
                        </span>
                        <h4 className="font-body font-bold mb-4">Zostań partnerem</h4>
                        <p>
                            Dołącz do nas i wpływaj na życie dzieci poprzez tworzenie innowacyjnych programów,
                            edukacyjnych projektów i inspirującego środowiska. Twoje umiejętności, pasja i zaangażowanie
                            mają kluczowe znaczenie dla naszej misji wszechstronnego rozwoju dzieci. Razem możemy
                            budować przyszłość, pomagając dzieciom osiągać pełen potencjał.
                        </p>
                    </div>
                    <div className="p-8 xl:p-12 md:w-2/6 flex flex-col items-start bg-white shadow-lg">
                        <span className="p-4 w-auto border border-text rounded-full text-primary mb-6">
                            <UserIcon className="w-6 h-6" />
                        </span>
                        <h4 className="font-body font-bold mb-4">Zostań współpracownikiem</h4>
                        <p>
                            Dołącz do nas jako współpracownik fundacji, aby mieć znaczący wpływ na rozwój dzieci i
                            wnosić wartość poprzez innowacyjne programy i inspirujące działania. Twoje umiejętności i
                            zaangażowanie są niezwykle ważne dla naszej misji wspierania wszechstronnego rozwoju dzieci.
                        </p>
                    </div>
                </div>
            </div>
            <div className="container mx-auto pt-14 pb-24">
                <div className="flex justify-center">
                    <Scrollchor
                        to="wsparcie"
                        className="btn btn-primary"
                        animate={{ offset: -120, duration: 1000, easing: easeOutQuad }}
                    >
                        Wesprzyj nasze działania
                        <CurrencyEuroIcon className="w-6 h-6" />
                    </Scrollchor>
                </div>
            </div>
        </section>
    );
};

export default Donation;
