import React from "react";
import ImgWithFallback from "./ImgWithFallback";

const Partners = () => {
    return (
        <section className="partners" id="partnerzy">
            <div className="container mx-auto mb-16">
                <h2 className="line-sep">Partnerzy</h2>
            </div>
            <div className="container mx-auto">
                <div className="flex flex-col md:flex-row gap-6">
                    <div className="md:w-5/12 flex flex-col">
                        <h4 className="font-bold mb-8">Patronat Honorowy</h4>
                        <div className="flex flex-wrap gap-5">
                            <div className="border">
                                <ImgWithFallback
                                    src="/images/partnerzy/msit.webp"
                                    fallback="/images/partnerzy/msit.png"
                                    alt="Ministerstwo Sportu i Turystyki"
                                />
                            </div>
                            <div className="border">
                                <ImgWithFallback
                                    src="/images/partnerzy/mein.webp"
                                    fallback="/images/partnerzy/mein.png"
                                    alt="Ministerstwo Edukacji i Nauki"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="md:w-3/12 flex flex-col">
                        <h4 className="font-bold mb-8">Patronat merytoryczny</h4>
                        <div className="flex gap-5">
                            <div className="border">
                                <ImgWithFallback
                                    src="/images/partnerzy/cenwg.webp"
                                    fallback="/images/partnerzy/cenwg.png"
                                    alt="Centrum edukacji nauczycieli w Gdańsku"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="md:w-4/12 flex flex-col">
                        <h4 className="font-bold mb-8">Partnerzy techniczni</h4>
                        <div className="flex flex-wrap gap-5">
                            <div className="border">
                                <ImgWithFallback
                                    src="/images/partnerzy/ks.webp"
                                    fallback="/images/partnerzy/ks.png"
                                    alt="Kids & Sport"
                                />
                            </div>
                            <div className="border">
                                <ImgWithFallback
                                    src="/images/partnerzy/ka2.webp"
                                    fallback="/images/partnerzy/ka2.png"
                                    alt="Kids & Art"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mx-auto">
                <div className="flex gap-6">
                    <div className="w-full flex flex-col">
                        <h4 className="font-bold mt-16 mb-8">Sponsorzy</h4>
                        <div className="flex flex-wrap gap-5">
                            <div className="border">
                                <ImgWithFallback
                                    src="/images/partnerzy/de.webp"
                                    fallback="/images/partnerzy/de.png"
                                    alt="Kids & Art"
                                />
                            </div>
                            <div className="border">
                                <ImgWithFallback
                                    src="/images/partnerzy/velvet.webp"
                                    fallback="/images/partnerzy/velvet.png"
                                    alt="Velvet"
                                />
                            </div>
                            <div className="border">
                                <ImgWithFallback
                                    src="/images/partnerzy/fsio.webp"
                                    fallback="/images/partnerzy/fsio.png"
                                    alt="Fundacja Szkiełko i Oko"
                                />
                            </div>
                            <div className="border">
                                <ImgWithFallback
                                    src="/images/partnerzy/fsp.webp"
                                    fallback="/images/partnerzy/fsp.png"
                                    alt="Fundacja Sportu Pozytywnego"
                                />
                            </div>
                            <div className="border">
                                <ImgWithFallback
                                    src="/images/partnerzy/ds.webp"
                                    fallback="/images/partnerzy/ds.png"
                                    alt="Dev-Soft"
                                />
                            </div>
                            <div className="border">
                                <ImgWithFallback
                                    src="/images/partnerzy/decathlon.webp"
                                    fallback="/images/partnerzy/decathlon.png"
                                    alt="Decathlon"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Partners;
