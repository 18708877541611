import React from "react";
import ImgWithFallback from "./ImgWithFallback";

const AboutUs = () => {
    return (
        <section className="about-us" id="o-nas">
            <div className="container mx-auto">
                <div className="flex flex-col md:flex-row items-center gap-6">
                    <div className="md:w-3/6 md:order-2 px-8 md:px-0">
                        <h2 className="line-sep">O nas</h2>
                        <p>
                            Zainspirowani latami pracy nad wszechstronnym rozwojem dzieci, w tym prowadzeniem zajęć i
                            programów szkoleniowych zarówno dla dzieci, jak i nauczycieli, dostrzegliśmy pilną potrzebę
                            w tym obszarze. W rezultacie, podjęliśmy decyzję o utworzeniu fundacji, która aktywnie
                            wspiera rozwój dziecka. Naszym celem jest zapewnienie dzieciom solidnego fundamentu do ich
                            przyszłego sukcesu i szczęścia, dostarczając im wsparcia, narzędzi i możliwości, które
                            promują ich pełny rozwój. Działamy w ścisłej współpracy z rodzicami, nauczycielami i innymi
                            zainteresowanymi stronami, aby zapewnić odpowiednie środowisko i zasoby, które pozwolą
                            dzieciom rozwijać się na wielu płaszczyznach. Nasza fundacja jest zobowiązana do budowania
                            przyszłości, w której dzieci stają się pewnymi siebie, twórczymi i odpowiedzialnymi
                            jednostkami, gotowymi sprostać wymaganiom współczesnego społeczeństwa.
                        </p>
                    </div>
                    <div className="md:w-3/6 md:order-1">
                        <ImgWithFallback src="/images/o-nas.webp" fallback="/images/o-nas.jpg" alt="O nas" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutUs;
