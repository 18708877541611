import React from "react";
import { Scrollchor, easeOutQuad } from "react-scrollchor";
import { ArrowSmallRightIcon } from "@heroicons/react/24/outline";

const TwoBox = () => {
    return (
        <section className="two-box">
            <div className="container mx-auto pt-24 md:pt-40">
                <div className="flex flex-col md:flex-row gap-6">
                    <div className="p-8 xl:p-12 md:w-3/6 flex flex-col items-start bg-white shadow">
                        <h3 className="line-sep">Lorem ipsum dolor sit</h3>
                        <p>
                            Ważne jest, aby dzieci otrzymywały wsparcie zarówno w domu, jak i w szkole oraz miały dostęp
                            do różnorodnych aktywności, które promują ich wszechstronny rozwój. Rodzice, nauczyciele i
                            opiekunowie mają kluczową rolę w zapewnianiu odpowiedniego środowiska, zasobów i okazji do
                            rozwoju dzieci. Działania takie jak zapewnianie zdrowego odżywiania, zapewnienie
                            emocjonalnego wsparcia, organizowanie edukacyjnych zajęć pozalekcyjnych i stwarzanie okazji
                            do interakcji społecznych są niezbędne w celu wspierania wszechstronnego rozwoju dzieci.
                        </p>
                    </div>
                    <div className="p-8 xl:p-12 md:w-3/6 flex flex-col items-start bg-white shadow">
                        <h3 className="line-sep">Lorem ipsum dolor sit</h3>
                        <p>
                            Wniosek jest taki, że pomaganie we wszechstronnym rozwoju dzieci jest niezwykle istotne dla
                            ich przyszłego sukcesu i dobrego samopoczucia. Kładzie się nacisk na równoczesny rozwój
                            fizyczny, emocjonalny, intelektualny, społeczny i moralny. Dzięki temu dzieci mają większe
                            szanse na osiągnięcie swojego pełnego potencjału i stania się pewnymi siebie,
                            odpowiedzialnymi i zrównoważonymi jednostkami w społeczeństwie.
                        </p>
                    </div>
                </div>
            </div>
            <div className="container mx-auto pt-14 pb-24">
                <div className="flex justify-center">
                    <Scrollchor
                        to="kontakt"
                        className="btn btn-black"
                        animate={{ offset: -120, duration: 1000, easing: easeOutQuad }}
                    >
                        Skontaktuj się z nami
                        <ArrowSmallRightIcon className="w-6 h-6" />
                    </Scrollchor>
                </div>
            </div>
        </section>
    );
};

export default TwoBox;
