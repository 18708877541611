import React from "react";
import LogoFooter from "./LogoFooter";

const Footer = () => {
    const date = new Date();

    return (
        <footer className="footer">
            <div className="container mx-auto">
                <div className="flex justify-center pt-24 pb-12 md:py-36">
                    <LogoFooter />
                </div>
            </div>
            <div className="container mx-auto">
                <div className="flex flex-col md:flex-row justify-between py-6 text-copyrightCK">
                    <div className="flex justify-center items-center gap-3 md:order-2 mb-8 md:mb-0">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                            <path
                                d="M26.67 4H5.33C4.97807 4.00262 4.6413 4.14358 4.39244 4.39244C4.14358 4.6413 4.00262 4.97807 4 5.33V26.67C4.00262 27.0219 4.14358 27.3587 4.39244 27.6076C4.6413 27.8564 4.97807 27.9974 5.33 28H16.82V18.72H13.7V15.09H16.82V12.42C16.82 9.32 18.71 7.63 21.49 7.63C22.42 7.63 23.35 7.63 24.28 7.77V11H22.37C20.86 11 20.57 11.72 20.57 12.77V15.08H24.17L23.7 18.71H20.57V28H26.67C27.0219 27.9974 27.3587 27.8564 27.6076 27.6076C27.8564 27.3587 27.9974 27.0219 28 26.67V5.33C27.9974 4.97807 27.8564 4.6413 27.6076 4.39244C27.3587 4.14358 27.0219 4.00262 26.67 4Z"
                                fill="white"
                            />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <g clipPath="url(#clip0_635_52)">
                                <path
                                    d="M12 8C10.9391 8 9.92172 8.42143 9.17157 9.17157C8.42143 9.92172 8 10.9391 8 12C8 13.0609 8.42143 14.0783 9.17157 14.8284C9.92172 15.5786 10.9391 16 12 16C13.0609 16 14.0783 15.5786 14.8284 14.8284C15.5786 14.0783 16 13.0609 16 12C16 10.9391 15.5786 9.92172 14.8284 9.17157C14.0783 8.42143 13.0609 8 12 8Z"
                                    fill="white"
                                />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M7.2 0C5.29044 0 3.45909 0.758569 2.10883 2.10883C0.758569 3.45909 0 5.29044 0 7.2L0 16.8C0 18.7096 0.758569 20.5409 2.10883 21.8912C3.45909 23.2414 5.29044 24 7.2 24H16.8C18.7096 24 20.5409 23.2414 21.8912 21.8912C23.2414 20.5409 24 18.7096 24 16.8V7.2C24 5.29044 23.2414 3.45909 21.8912 2.10883C20.5409 0.758569 18.7096 0 16.8 0L7.2 0ZM6.4 12C6.4 10.5148 6.99 9.09041 8.0402 8.0402C9.09041 6.99 10.5148 6.4 12 6.4C13.4852 6.4 14.9096 6.99 15.9598 8.0402C17.01 9.09041 17.6 10.5148 17.6 12C17.6 13.4852 17.01 14.9096 15.9598 15.9598C14.9096 17.01 13.4852 17.6 12 17.6C10.5148 17.6 9.09041 17.01 8.0402 15.9598C6.99 14.9096 6.4 13.4852 6.4 12ZM17.6 6.4H19.2V4.8H17.6V6.4Z"
                                    fill="white"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_635_52">
                                    <rect width="24" height="24" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                            <path
                                d="M28.075 7.5C27.1125 7.9375 26.075 8.225 25 8.3625C26.1 7.7 26.95 6.65 27.35 5.3875C26.3125 6.0125 25.1625 6.45 23.95 6.7C22.9625 5.625 21.575 5 20 5C17.0625 5 14.6625 7.4 14.6625 10.3625C14.6625 10.7875 14.7125 11.2 14.8 11.5875C10.35 11.3625 6.38755 9.225 3.75005 5.9875C3.28755 6.775 3.02505 7.7 3.02505 8.675C3.02505 10.5375 3.96255 12.1875 5.41255 13.125C4.52505 13.125 3.70005 12.875 2.97505 12.5V12.5375C2.97505 15.1375 4.82505 17.3125 7.27505 17.8C6.48846 18.0153 5.66267 18.0452 4.86255 17.8875C5.20206 18.9531 5.86697 19.8855 6.76382 20.5537C7.66067 21.2218 8.74436 21.5921 9.86255 21.6125C7.96709 23.113 5.61754 23.9241 3.20005 23.9125C2.77505 23.9125 2.35005 23.8875 1.92505 23.8375C4.30005 25.3625 7.12505 26.25 10.15 26.25C20 26.25 25.4125 18.075 25.4125 10.9875C25.4125 10.75 25.4125 10.525 25.4 10.2875C26.45 9.5375 27.35 8.5875 28.075 7.5Z"
                                fill="white"
                            />
                        </svg>
                    </div>
                    <div className="uppercase text-center md:text-left md:order-1">
                        {date.getFullYear()} Fundacja CONNECT4KIDS ALL RIGHTS RESERVED
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
