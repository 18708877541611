const Logo = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="238" height="53" viewBox="0 0 238 53" fill="none">
            <g clipPath="url(#clip0_635_3)">
                <path
                    d="M0 9.18087V0.179138H6.13822V2.08052H2.19189V3.81657H6.0231V5.71796H2.19189V9.17628H0V9.18087Z"
                    fill="#39A5DD"
                />
                <path
                    d="M8.66162 5.51588V0.179138H10.8535V5.53884C10.8535 6.85236 11.6133 7.45401 12.6862 7.45401C13.7592 7.45401 14.519 6.84777 14.519 5.53884V0.179138H16.7108V5.51588C16.7108 7.89491 15.0209 9.35999 12.6908 9.35999C10.3608 9.35999 8.67083 7.89491 8.67083 5.51588H8.66162Z"
                    fill="#39A5DD"
                />
                <path
                    d="M26.2704 0.179138H28.4623V9.18087H26.5283L21.9373 3.83035V9.18087H19.7454V0.179138H21.6794L26.2704 5.53884V0.179138Z"
                    fill="#39A5DD"
                />
                <path
                    d="M34.4073 0.179138C38.0174 0.179138 39.9238 2.12186 39.9238 4.68C39.9238 7.23815 38.0174 9.18087 34.4073 9.18087H31.5569V0.179138H34.4073ZM34.2 7.33919C36.4564 7.33919 37.7319 6.43902 37.7319 4.68C37.7319 2.92099 36.4564 2.01623 34.2 2.01623H33.7488V7.33919H34.2Z"
                    fill="#39A5DD"
                />
                <path
                    d="M48.0051 7.90869H44.2522L43.8009 9.18087H41.4294L45.3113 0.179138H46.9598L50.8417 9.18087H48.4702L48.0051 7.90869ZM44.8969 6.12212H47.3604L46.1356 2.7281L44.8969 6.12212Z"
                    fill="#39A5DD"
                />
                <path
                    d="M59.531 3.38024C59.181 2.54437 58.3982 1.90139 57.1595 1.90139C55.4327 1.90139 54.5439 3.15061 54.5439 4.67998C54.5439 6.20936 55.4327 7.45858 57.1595 7.45858C58.3982 7.45858 59.1856 6.8156 59.531 5.97972H61.709C61.2854 7.78007 59.6092 9.35997 57.1733 9.35997C54.1848 9.35997 52.3521 7.22435 52.3521 4.67998C52.3521 2.13562 54.1848 0 57.1733 0C59.6092 0 61.2854 1.5799 61.709 3.38024H59.531Z"
                    fill="#39A5DD"
                />
                <path
                    d="M64.3891 0.179138H70.8082V6.23694C70.8082 7.85817 69.5465 9.35999 67.4697 9.35999C65.2778 9.35999 64.2095 7.98217 64.1174 6.10834H66.1804C66.2172 6.81562 66.5396 7.48616 67.4191 7.48616C68.1559 7.48616 68.6163 7.00851 68.6163 6.23694V2.05756H64.3891V0.179138Z"
                    fill="#39A5DD"
                />
                <path
                    d="M79.5759 7.90869H75.823L75.3717 9.18087H73.0002L76.8775 0.179138H78.526L82.4079 9.18087H80.0364L79.5713 7.90869H79.5759ZM76.4677 6.12212H78.9313L77.7064 2.7281L76.4677 6.12212Z"
                    fill="#39A5DD"
                />
                <path
                    d="M2.19189 50.6256C0.732166 49.0457 0 46.8182 0 43.9386V24.1302C0 21.2505 0.732166 19.0185 2.19189 17.4386C3.65162 15.8587 5.77444 15.0687 8.55575 15.0687C11.3371 15.0687 13.4599 15.8587 14.9196 17.4386C16.3793 19.0185 17.1115 21.2505 17.1115 24.1302V28.0294H11.618V23.7627C11.618 21.4802 10.6509 20.3366 8.71232 20.3366C6.77369 20.3366 5.80668 21.4802 5.80668 23.7627V44.3611C5.80668 46.6115 6.77369 47.7322 8.71232 47.7322C10.6509 47.7322 11.618 46.6069 11.618 44.3611V38.7258H17.1115V43.9432C17.1115 46.8228 16.3793 49.0549 14.9196 50.6348C13.4599 52.2146 11.3371 53.0046 8.55575 53.0046C5.77444 53.0046 3.65162 52.2146 2.19189 50.6348V50.6256Z"
                    fill="#1D1E1C"
                />
                <path
                    d="M22.5544 50.575C21.0394 48.9584 20.2842 46.6758 20.2842 43.7273V24.3414C20.2842 21.3929 21.0394 19.1103 22.5544 17.4937C24.0693 15.877 26.252 15.0687 29.1024 15.0687C31.9528 15.0687 34.1401 15.877 35.6505 17.4937C37.1654 19.1103 37.9206 21.3929 37.9206 24.3414V43.7273C37.9206 46.6758 37.1608 48.963 35.6505 50.575C34.1355 52.1917 31.9528 53 29.1024 53C26.252 53 24.0647 52.1917 22.5544 50.575ZM32.114 44.0947V23.9694C32.114 21.5445 31.1101 20.332 29.1024 20.332C27.0947 20.332 26.0909 21.5445 26.0909 23.9694V44.0947C26.0909 46.5197 27.0947 47.7322 29.1024 47.7322C31.1101 47.7322 32.114 46.5197 32.114 44.0947Z"
                    fill="#1D1E1C"
                />
                <path
                    d="M41.8347 15.5923H49.1241L54.7743 37.6649H54.8802V15.5923H60.056V52.4672H54.0881L47.1164 25.5493H47.0105V52.4672H41.8347V15.5923Z"
                    fill="#1D1E1C"
                />
                <path
                    d="M64.3845 15.5923H71.6739L77.3241 37.6649H77.43V15.5923H82.6058V52.4672H76.6379L69.6662 25.5493H69.5603V52.4672H64.3845V15.5923Z"
                    fill="#1D1E1C"
                />
                <path
                    d="M86.939 15.5923H102.784V20.8601H92.7502V30.6059H100.726V35.8737H92.7502V47.1994H102.784V52.4672H86.939V15.5923Z"
                    fill="#1D1E1C"
                />
                <path
                    d="M107.937 50.6256C106.477 49.0457 105.745 46.8136 105.745 43.934V24.1256C105.745 21.2459 106.477 19.0139 107.937 17.434C109.397 15.8541 111.52 15.0641 114.301 15.0641C117.082 15.0641 119.205 15.8541 120.665 17.434C122.124 19.0139 122.857 21.2459 122.857 24.1256V28.0248H117.363V23.7582C117.363 21.4756 116.396 20.332 114.457 20.332C112.519 20.332 111.552 21.4756 111.552 23.7582V44.3565C111.552 46.607 112.519 47.7276 114.457 47.7276C116.396 47.7276 117.363 46.6024 117.363 44.3565V38.7213H122.857V43.9386C122.857 46.8182 122.124 49.0503 120.665 50.6302C119.205 52.2101 117.082 53 114.301 53C111.52 53 109.397 52.2101 107.937 50.6302V50.6256Z"
                    fill="#1D1E1C"
                />
                <path
                    d="M130.887 20.8601H124.813V15.5923H142.772V20.8601H136.699V52.4672H130.887V20.8601Z"
                    fill="#1D1E1C"
                />
                <path
                    d="M155.288 45.7802H144.195V40.5124L154.759 15.5923H161.095V40.5124H163.839V45.7802H161.095V52.4718H155.284V45.7802H155.288ZM155.288 40.5124V26.1326H155.182L149.477 40.5124H155.288Z"
                    fill="#39A5DD"
                />
                <path
                    d="M168.223 15.5923H174.034V31.1341L181.43 15.5923H187.241L180.32 29.1316L187.347 52.4672H181.273L176.36 36.0299L174.034 40.7191V52.4672H168.223V15.5923Z"
                    fill="#1D1E1C"
                />
                <path d="M190.092 15.5923H195.903V52.4672H190.092V15.5923Z" fill="#1D1E1C" />
                <path
                    d="M200.231 15.5923H209.105C211.992 15.5923 214.156 16.3639 215.602 17.9116C217.043 19.4594 217.766 21.7236 217.766 24.7088V43.3599C217.766 46.3451 217.043 48.6094 215.602 50.1571C214.156 51.7048 211.992 52.4764 209.105 52.4764H200.231V15.5923ZM208.999 47.1994C209.947 47.1994 210.68 46.9192 211.191 46.3589C211.702 45.7986 211.955 44.8847 211.955 43.6217V24.4471C211.955 23.1841 211.697 22.2701 211.191 21.7098C210.68 21.1495 209.947 20.8693 208.999 20.8693H206.042V47.2086H208.999V47.1994Z"
                    fill="#1D1E1C"
                />
                <path
                    d="M222.841 50.6026C221.395 49.0043 220.677 46.7126 220.677 43.7273V41.6192H226.17V44.1498C226.17 46.538 227.174 47.7322 229.182 47.7322C230.167 47.7322 230.918 47.4428 231.429 46.8641C231.94 46.2854 232.193 45.3439 232.193 44.0442C232.193 42.501 231.839 41.137 231.139 39.9613C230.434 38.7855 229.131 37.371 227.229 35.7222C224.835 33.6141 223.163 31.7082 222.21 30.0043C221.262 28.3004 220.783 26.376 220.783 24.2358C220.783 21.3194 221.524 19.0644 223.002 17.4661C224.48 15.8679 226.631 15.0687 229.444 15.0687C232.258 15.0687 234.33 15.8679 235.758 17.4661C237.185 19.0644 237.899 21.3562 237.899 24.3414V25.8708H232.405V23.974C232.405 22.711 232.157 21.7879 231.664 21.2092C231.171 20.6305 230.448 20.3412 229.5 20.3412C227.561 20.3412 226.594 21.5169 226.594 23.873C226.594 25.2095 226.953 26.4541 227.676 27.6114C228.399 28.7688 229.711 30.1742 231.613 31.8276C234.045 33.9356 235.716 35.8508 236.632 37.5685C237.549 39.2907 238.005 41.3069 238.005 43.6263C238.005 46.6483 237.254 48.963 235.758 50.5796C234.261 52.1963 232.087 53.0046 229.233 53.0046C226.378 53.0046 224.287 52.2055 222.841 50.6072V50.6026Z"
                    fill="#1D1E1C"
                />
            </g>
            <defs>
                <clipPath id="clip0_635_3">
                    <rect width="238" height="53" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Logo;
