const LogoFooter = () => {
    return (
        <svg width="310" height="69" viewBox="0 0 310 69" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_635_59)">
                <path
                    d="M0 11.9526V0.233398H7.99516V2.70879H2.85499V4.96893H7.84522V7.44432H2.85499V11.9467H0V11.9526Z"
                    fill="#39A5DD"
                />
                <path
                    d="M11.282 7.18123V0.233398H14.137V7.21113C14.137 8.92118 15.1266 9.70445 16.5241 9.70445C17.9216 9.70445 18.9113 8.9152 18.9113 7.21113V0.233398H21.7663V7.18123C21.7663 10.2785 19.565 12.1858 16.5301 12.1858C13.4952 12.1858 11.294 10.2785 11.294 7.18123H11.282Z"
                    fill="#39A5DD"
                />
                <path
                    d="M34.218 0.233398H37.073V11.9526H34.5539L28.574 4.98686V11.9526H25.719V0.233398H28.2381L34.218 7.21113V0.233398Z"
                    fill="#39A5DD"
                />
                <path
                    d="M44.8162 0.233398C49.5185 0.233398 52.0016 2.7626 52.0016 6.09302C52.0016 9.42343 49.5185 11.9526 44.8162 11.9526H41.1035V0.233398H44.8162ZM44.5463 9.55497C47.4853 9.55497 49.1467 8.38305 49.1467 6.09302C49.1467 3.80298 47.4853 2.62508 44.5463 2.62508H43.9585V9.55497H44.5463Z"
                    fill="#39A5DD"
                />
                <path
                    d="M62.5279 10.2964H57.6396L57.0518 11.9526H53.9629L59.0191 0.233398H61.1663L66.2225 11.9526H63.1336L62.5279 10.2964ZM58.4793 7.97049H61.6881L60.0927 3.55186L58.4793 7.97049Z"
                    fill="#39A5DD"
                />
                <path
                    d="M77.5404 4.40069C77.0845 3.31248 76.0649 2.47539 74.4515 2.47539C72.2023 2.47539 71.0447 4.10173 71.0447 6.09281C71.0447 8.08388 72.2023 9.71023 74.4515 9.71023C76.0649 9.71023 77.0905 8.87314 77.5404 7.78492H80.3774C79.8256 10.1288 77.6423 12.1856 74.4695 12.1856C70.5769 12.1856 68.1897 9.40529 68.1897 6.09281C68.1897 2.78033 70.5769 0 74.4695 0C77.6423 0 79.8256 2.05685 80.3774 4.40069H77.5404Z"
                    fill="#39A5DD"
                />
                <path
                    d="M83.8683 0.233398H92.2293V8.11997C92.2293 10.2306 90.5859 12.1858 87.8809 12.1858C85.0259 12.1858 83.6344 10.3921 83.5144 7.95255H86.2014C86.2494 8.87335 86.6693 9.74631 87.8149 9.74631C88.7745 9.74631 89.3743 9.12447 89.3743 8.11997V2.67889H83.8683V0.233398Z"
                    fill="#39A5DD"
                />
                <path
                    d="M103.649 10.2964H98.7609L98.1731 11.9526H95.0842L100.134 0.233398H102.282L107.338 11.9526H104.249L103.643 10.2964H103.649ZM99.6006 7.97049H102.809L101.214 3.55186L99.6006 7.97049Z"
                    fill="#39A5DD"
                />
                <path
                    d="M2.85499 65.9087C0.953662 63.8518 0 60.9519 0 57.2029V31.4146C0 27.6657 0.953662 24.7598 2.85499 22.7029C4.75631 20.6461 7.52133 19.6177 11.144 19.6177C14.7668 19.6177 17.5318 20.6461 19.4331 22.7029C21.3344 24.7598 22.2881 27.6657 22.2881 31.4146V36.491H15.1326V30.9363C15.1326 27.9646 13.8731 26.4758 11.348 26.4758C8.82287 26.4758 7.56332 27.9646 7.56332 30.9363V57.753C7.56332 60.6828 8.82287 62.1418 11.348 62.1418C13.8731 62.1418 15.1326 60.6769 15.1326 57.753V50.4165H22.2881V57.2089C22.2881 60.9579 21.3344 63.8638 19.4331 65.9206C17.5318 67.9775 14.7668 69.0059 11.144 69.0059C7.52133 69.0059 4.75631 67.9775 2.85499 65.9206V65.9087Z"
                    fill="white"
                />
                <path
                    d="M29.3776 65.8429C27.4043 63.7382 26.4207 60.7665 26.4207 56.9279V31.6897C26.4207 27.851 27.4043 24.8794 29.3776 22.7747C31.3509 20.67 34.1939 19.6177 37.9066 19.6177C41.6193 19.6177 44.4682 20.67 46.4356 22.7747C48.4089 24.8794 49.3925 27.851 49.3925 31.6897V56.9279C49.3925 60.7665 48.4029 63.7442 46.4356 65.8429C44.4623 67.9476 41.6193 68.9999 37.9066 68.9999C34.1939 68.9999 31.3449 67.9476 29.3776 65.8429ZM41.8292 57.4062V31.2054C41.8292 28.0483 40.5217 26.4698 37.9066 26.4698C35.2915 26.4698 33.984 28.0483 33.984 31.2054V57.4062C33.984 60.5633 35.2915 62.1418 37.9066 62.1418C40.5217 62.1418 41.8292 60.5633 41.8292 57.4062Z"
                    fill="white"
                />
                <path
                    d="M54.4907 20.2993H63.9854L71.3447 49.0354H71.4827V20.2993H78.2243V68.3063H70.4511L61.3703 33.2622H61.2323V68.3063H54.4907V20.2993Z"
                    fill="white"
                />
                <path
                    d="M83.8623 20.2993H93.3569L100.716 49.0354H100.854V20.2993H107.596V68.3063H99.8226L90.7419 33.2622H90.6039V68.3063H83.8623V20.2993Z"
                    fill="white"
                />
                <path
                    d="M113.24 20.2993H133.878V27.1575H120.809V39.8453H131.197V46.7035H120.809V61.4482H133.878V68.3063H113.24V20.2993Z"
                    fill="white"
                />
                <path
                    d="M140.59 65.9088C138.689 63.8519 137.735 60.946 137.735 57.1971V31.4088C137.735 27.6598 138.689 24.7539 140.59 22.6971C142.491 20.6402 145.256 19.6118 148.879 19.6118C152.502 19.6118 155.267 20.6402 157.168 22.6971C159.07 24.7539 160.023 27.6598 160.023 31.4088V36.4851H152.868V30.9304C152.868 27.9588 151.608 26.47 149.083 26.47C146.558 26.47 145.298 27.9588 145.298 30.9304V57.7472C145.298 60.677 146.558 62.1359 149.083 62.1359C151.608 62.1359 152.868 60.671 152.868 57.7472V50.4107H160.023V57.2031C160.023 60.952 159.07 63.8579 157.168 65.9148C155.267 67.9716 152.502 69 148.879 69C145.256 69 142.491 67.9716 140.59 65.9148V65.9088Z"
                    fill="white"
                />
                <path
                    d="M170.483 27.1575H162.572V20.2993H185.964V27.1575H178.053V68.3063H170.483V27.1575Z"
                    fill="white"
                />
                <path
                    d="M202.266 59.6006H187.817V52.7425L201.576 20.2993H209.83V52.7425H213.404V59.6006H209.83V68.3123H202.26V59.6006H202.266ZM202.266 52.7425V34.0216H202.128L194.697 52.7425H202.266Z"
                    fill="#39A5DD"
                />
                <path
                    d="M219.114 20.2993H226.684V40.5329L236.316 20.2993H243.885L234.871 37.926L244.023 68.3063H236.112L229.712 46.9068L226.684 53.0115V68.3063H219.114V20.2993Z"
                    fill="white"
                />
                <path d="M247.598 20.2993H255.167V68.3063H247.598V20.2993Z" fill="white" />
                <path
                    d="M260.805 20.2993H272.363C276.124 20.2993 278.943 21.3038 280.826 23.3188C282.704 25.3338 283.645 28.2816 283.645 32.168V56.4496C283.645 60.3361 282.704 63.2838 280.826 65.2988C278.943 67.3138 276.124 68.3183 272.363 68.3183H260.805V20.2993ZM272.225 61.4482C273.461 61.4482 274.415 61.0835 275.08 60.354C275.746 59.6245 276.076 58.4347 276.076 56.7904V31.8272C276.076 30.1829 275.74 28.9931 275.08 28.2636C274.415 27.5342 273.461 27.1694 272.225 27.1694H268.375V61.4601H272.225V61.4482Z"
                    fill="white"
                />
                <path
                    d="M290.255 65.8788C288.372 63.798 287.436 60.8144 287.436 56.9279V54.1834H294.591V57.478C294.591 60.5872 295.899 62.1418 298.514 62.1418C299.798 62.1418 300.775 61.7651 301.441 61.0117C302.107 60.2583 302.437 59.0326 302.437 57.3405C302.437 55.3315 301.975 53.5556 301.063 52.025C300.146 50.4943 298.448 48.6527 295.971 46.5061C292.852 43.7617 290.675 41.2803 289.433 39.062C288.198 36.8438 287.574 34.3385 287.574 31.5522C287.574 27.7554 288.54 24.8196 290.465 22.7388C292.39 20.6581 295.191 19.6177 298.856 19.6177C302.521 19.6177 305.22 20.6581 307.079 22.7388C308.938 24.8196 309.868 27.8032 309.868 31.6897V33.6808H302.713V31.2113C302.713 29.5671 302.389 28.3652 301.747 27.6119C301.105 26.8585 300.164 26.4818 298.928 26.4818C296.403 26.4818 295.143 28.0125 295.143 31.0798C295.143 32.8198 295.611 34.4401 296.553 35.9469C297.494 37.4536 299.204 39.2833 301.681 41.4358C304.848 44.1802 307.025 46.6736 308.219 48.9098C309.412 51.152 310.006 53.7769 310.006 56.7964C310.006 60.7307 309.028 63.7442 307.079 65.8489C305.13 67.9535 302.299 69.0059 298.58 69.0059C294.861 69.0059 292.138 67.9655 290.255 65.8847V65.8788Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_635_59">
                    <rect width="310" height="69" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default LogoFooter;
