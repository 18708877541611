import React from "react";
import { UserIcon } from "@heroicons/react/24/outline";

const Boxes = () => {
    return (
        <section className="boxes">
            <div className="container mx-auto">
                <div className="flex flex-col md:flex-row gap-6">
                    <div className="p-8 xl:p-12 md:w-2/6 flex flex-col items-start bg-white shadow-lg">
                        <span className="p-4 w-auto border border-text rounded-full text-primary mb-6">
                            <UserIcon className="w-6 h-6" />
                        </span>
                        <h4 className="font-body font-bold mb-4">Rozwój fizyczny</h4>
                        <p>
                            Fizyczny rozwój dzieci jest istotny dla ich ogólnej kondycji i zdrowia. Dzieci powinny być
                            zachęcane do aktywności fizycznej, takiej jak zabawa na świeżym powietrzu, sporty, gry
                            zespołowe i zajęcia rekreacyjne. Wsparcie w zdrowym stylu życia, odpowiednim odżywianiu i
                            regularnych badaniach medycznych również wpływa na fizyczny rozwój dziecka.
                        </p>
                    </div>
                    <div className="p-8 xl:p-12 md:w-2/6 flex flex-col items-start bg-white shadow-lg">
                        <span className="p-4 w-auto border border-text rounded-full text-primary mb-6">
                            <UserIcon className="w-6 h-6" />
                        </span>
                        <h4 className="font-body font-bold mb-4">Rozwój emocjonalny</h4>
                        <p>
                            Rozwój emocjonalny jest również niezwykle istotny. Dzieci powinny być otoczone miłością,
                            akceptacją i wsparciem emocjonalnym. Pomoc w rozwijaniu umiejętności radzenia sobie ze
                            stresem, budowaniu pozytywnej samooceny i rozumieniu i wyrażaniu swoich emocji jest kluczowa
                            dla ich zdrowia psychicznego i dobrego samopoczucia.
                        </p>
                    </div>
                    <div className="p-8 xl:p-12 md:w-2/6 flex flex-col items-start bg-white shadow-lg">
                        <span className="p-4 w-auto border border-text rounded-full text-primary mb-6">
                            <UserIcon className="w-6 h-6" />
                        </span>
                        <h4 className="font-body font-bold mb-4">Rozwój intelektualny</h4>
                        <p>
                            Rozwój intelektualny obejmuje rozwijanie umiejętności poznawczych, takich jak myślenie
                            krytyczne, logiczne rozumowanie, pamięć, koncentracja i umiejętność rozwiązywania problemów.
                            Dzieci powinny być zachęcane do eksploracji, odkrywania świata, czytania, nauki matematyki i
                            innych dziedzin. Stymulujące środowisko, ciekawe zadania, gry edukacyjne i rozmowy o
                            różnorodnych tematach pomagają rozwijać umiejętności intelektualne.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Boxes;
