import React from "react";

const BankAccount = () => {
    return (
        <section className="bank-account" id="wsparcie">
            <div className="container mx-auto py-24">
                <div className="flex flex-col items-center">
                    <h2 className="line-sep line-center">Możesz wesprzeć nas bezpośrednio na konto</h2>
                    <h4 className="font-bold text-xl text-primary">
                        ING Bank Śląski S.A, 10 1050 1012 1000 0090 3289 8638
                    </h4>
                </div>
            </div>
        </section>
    );
};

export default BankAccount;
