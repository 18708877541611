import React from "react";
import ContactForm from "./ContactForm";
import { MapPinIcon, DevicePhoneMobileIcon, EnvelopeIcon } from "@heroicons/react/24/outline";

const Contact = () => {
    return (
        <section className="contact" id="kontakt">
            <div className="contact-form">
                <h2 className="mb-12">Napisz do nas</h2>
                <ContactForm />
            </div>
            <div className="flex flex-col items-start gap-8 xl:gap-12 py-24 px-8 xl:p-24 md:w-5/12">
                <h2>Dane kontaktowe</h2>
                <div className="flex items-center gap-4">
                    <MapPinIcon className="w-6 h-6 stroke-primary" />
                    <span className="">Fundacja Connect4Kids</span>
                </div>
                <div className="border-b w-full xl:w-80"></div>
                <div className="flex items-center gap-4">
                    <EnvelopeIcon className="w-6 h-6 stroke-primary" />
                    <span className="">fundacja@connect4kids.pl</span>
                </div>
            </div>
        </section>
    );
};

export default Contact;
