import React from "react";
import Logo from "./Logo";
import Menu from "./Menu";

const Header = () => {
    return (
        <div className="header">
            <div className="container mx-auto">
                <div className="flex flex-row md:flex-col justify-between items-center px-8">
                    <a href="/">
                        <Logo />
                    </a>
                    <Menu />
                </div>
            </div>
        </div>
    );
};

export default Header;
