import React from "react";
import { Scrollchor, easeOutQuad } from "react-scrollchor";
import { ArrowSmallRightIcon } from "@heroicons/react/24/outline";

const Slider = () => {
    return (
        <section className="slider">
            <div className="content flex flex-col gap-7 items-center max-w-2xl text-white text-center">
                <h1 className="text-7xl">Pomagamy</h1>
                <h2 className="text-4xl font-body">
                    we <span className="text-primary">wszechstronnym</span> rozwoju dzieci
                </h2>
                <p>
                    to ważne i wszechstronne podejście do wspierania rozwoju dzieci we wszystkich sferach ich życia.
                    Rozwój dziecka obejmuje nie tylko aspekty fizyczne, ale również emocjonalne, intelektualne,
                    społeczne i moralne. Skupienie się na tych wszystkich obszarach jest kluczowe dla zapewnienia
                    pełnego rozwoju dziecka i pomocy mu w osiąganiu swojego pełnego potencjału.
                </p>
                <Scrollchor
                    to="kontakt"
                    className="btn btn-primary"
                    animate={{ offset: -120, duration: 1000, easing: easeOutQuad }}
                >
                    Skontaktuj się z nami
                    <ArrowSmallRightIcon className="w-6 h-6" />
                </Scrollchor>
            </div>
        </section>
    );
};

export default Slider;
