import React, { useState } from "react";
import axios from "axios";
import { PaperAirplaneIcon } from "@heroicons/react/24/outline";

const ContactForm = () => {
    const [nameInput, setNameInput] = useState("");
    const [emailInput, setEmailInput] = useState("");
    const [messageInput, setMessageInput] = useState("");
    const [termsInput, setTermsInput] = useState(false);
    const [formSuccess, setFormSuccess] = useState(false);
    const [formError, setFormError] = useState(false);
    const [formButtonStatus, setFormButtonStatus] = useState("Wyślij");

    const onSubmit = (e) => {
        e.preventDefault();
        setFormButtonStatus("Wysyłanie...");

        const { name, email, message, terms } = e.target.elements;
        let formData = {
            name: name.value,
            email: email.value,
            message: message.value,
            terms: terms.value,
        };

        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}`,
            data: formData,
        })
            .then((result) => {
                setFormButtonStatus("Wyślij");

                if (false == result.data.sent) {
                    setFormSuccess(false);
                    setFormError(result.data.message);
                } else {
                    setNameInput("");
                    setEmailInput("");
                    setMessageInput("");
                    setTermsInput(false);

                    setFormSuccess(result.data.message);
                    setFormError(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setFormButtonStatus("Wyślij");
                setFormSuccess(false);
                setFormError(error.message);
            });
    };

    return (
        <div>
            {formSuccess && <div className="success">{formSuccess}</div>}
            {formError && <div className="error">{formError}</div>}
            <form onSubmit={onSubmit} method="POST" className="w-full">
                <div className="flex flex-col gap-4 w-full">
                    <input
                        type="text"
                        name="name"
                        className="form-input"
                        value={nameInput}
                        onChange={(e) => setNameInput(e.target.value)}
                        required
                        placeholder="Imię i Nazwisko"
                    />
                    <input
                        type="email"
                        name="email"
                        className="form-input"
                        value={emailInput}
                        onChange={(e) => setEmailInput(e.target.value)}
                        required
                        placeholder="E-mail"
                    />
                    <textarea
                        name="message"
                        className="form-textarea"
                        value={messageInput}
                        onChange={(e) => setMessageInput(e.target.value)}
                        required
                        placeholder="Wiadomość"
                        rows={6}
                    />
                    <label>
                        <input
                            type="checkbox"
                            name="terms"
                            value={"Zgoda"}
                            className="form-checkbox mr-4"
                            checked={termsInput && "checked"}
                            onChange={(e) => setTermsInput(e.target.checked)}
                            required
                        />
                        Wysyłając formularz wyrażam zgodę na przetwarzanie swoich danych osobowych zgodnie z ustawą o
                        ochronie danych osobowych.
                    </label>
                    <button type="submit" className="btn btn-black ml-auto">
                        {formButtonStatus}
                        <PaperAirplaneIcon className="w-6 h-6" />
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ContactForm;
