import React from "react";
import ImgWithFallback from "./ImgWithFallback";
import { ArrowSmallRightIcon } from "@heroicons/react/24/outline";

const Actions = () => {
    return (
        <section className="actions" id="nasze-programy">
            <div className="action-zis">
                <ImgWithFallback
                    src="/images/zdrowo-i-sportowo-logo.webp"
                    fallback="/images/zdrowo-i-sportowo-logo.png"
                    alt="Zdrowo i Sportowo"
                />
                {/* <h4 className="mt-16 mb-8 font-bold">Lorem ipsum dolor sit</h4> */}
                <p className="mt-16">
                    Dla rodziców, nauczycieli, wychowawców, trenerów oraz dzieci „Zdrowo i Sportowo” to ogólnopolski
                    program edukacyjny skierowany do nauczycieli, wychowawców i trenerów dzieci w wieku przedszkolnym
                    oraz wczesnoszkolnym (klasy 1-3), a więc do 10 roku życia. To również kampania skierowana do
                    rodziców i wszystkich opiekunów dzieci.
                </p>
                <a
                    href="https://zdrowoisportowo.edu.pl/"
                    className="btn btn-black mt-16"
                    target="_blank"
                    rel="noopener"
                >
                    Strona akcji
                    <ArrowSmallRightIcon className="w-6 h-6" />
                </a>
            </div>
            <div className="action-sw">
                <ImgWithFallback
                    src="/images/sztuka-widzenia-logo.webp"
                    fallback="/images/sztuka-widzenia-logo.png"
                    alt="Sztuka widzenia"
                />
                {/* <h4 className="mt-16 mb-8 font-bold">Lorem ipsum dolor sit</h4> */}
                <p className="mt-16">
                    Zapraszamy do miejsca, gdzie razem będziemy odkrywać świat wizualny: fotografię, malarstwo, film,
                    sztukę nowoczesną. Miejsca, gdzie najważniejszy nie jest produkt, czyli obiekt sztuki ale proces
                    jego tworzenia. ART = process not product. Zapraszamy dzieci i dorosłych, uczniów i nauczycieli,
                    osoby, w życiu których sztuka zajmuje stałe miejsce ale też tych, którzy dopiero chcą się ze sztuką
                    zapoznać. Zapraszamy wszystkich!
                </p>
                <a href="https://sztukawidzenia.edu.pl/" className="btn btn-pink mt-16" target="_blank" rel="noopener">
                    Strona akcji
                    <ArrowSmallRightIcon className="w-6 h-6" />
                </a>
            </div>
        </section>
    );
};

export default Actions;
